import React from 'react'
import { useState, useEffect } from '@wordpress/element'
import PropTypes from 'prop-types'
import Flickity from 'react-flickity-component'
import './style.scss'

import elEqualHeight from './dom-handler'

//import l10n from '../../shared/l10n'
// const { botDetected } = l10n

const FeaturedCarousel = ({ slides, isTablet }) => {
  const [flkty, setFlkty] = useState(undefined)

  useEffect(() => {
    if (flkty != undefined) {
      elEqualHeight('.matchHeight', '.excerptHeight')
      setTimeout(() => flkty.resize(), 300)
    }
  }, [flkty])

  let flickityOptions = {
    pageDots: false,
    prevNextButtons: false,
    adaptiveHeight: false,
    wrapAround: true,
  }

  return (
    <>
      {/*{!botDetected && (*/}
      <div className="popular-posts">
        {isTablet ? (
          <Flickity
            className={'flickity-styles'}
            elementType={'div'}
            options={flickityOptions}
            disableImagesLoaded={false}
            reloadOnUpdate
            static
            flickityRef={setFlkty}
          >
            {slides.map((post, i) => (
              <div key={i} className="header-slider__item">
                <div dangerouslySetInnerHTML={{ __html: post }} />
              </div>
            ))}
          </Flickity>
        ) : (
          <div className="row">
            {slides.map((post, i) => (
              <div
                className="col-md-4"
                key={i}
                dangerouslySetInnerHTML={{ __html: post }}
              />
            ))}
          </div>
        )}
      </div>
      {/*)}*/}
    </>
  )
}

FeaturedCarousel.propTypes = {
  isTablet: PropTypes.bool,
  slides: PropTypes.array.isRequired,
  isPrev: PropTypes.bool, // check if in admin
}

FeaturedCarousel.defaultProps = {
  isTablet: false,
  isPrev: false,
}

export default FeaturedCarousel
