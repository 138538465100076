import 'jquery-match-height'

export default (selector, excerpt) => {
  $(selector).matchHeight({
    byRow: false,
  })

  $(excerpt).matchHeight({
    byRow: false,
  })
}
